export const ESTeams = [
  {
    Id: 1,
    Pos_1: 'yuria',
    Pos_2: 'larimar',
    Pos_3: 'rose-prominence',
    Pos_4: 'lilith',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Assault',
    Mode: 'Guild Raid Charite',
    Treasure: 'Arrowhead/ Cursed Sword/ Glass Cannon',
    Explanation: '{13-Mar-2025} Quick Job anomaly active. KR public comp',
    Subs: 'Ult: CMephi ult',
    Gear: 'Gear: CMephi - CDMG, Rest Speed'
  },
  {
    Id: 2,
    Pos_1: 'yuria',
    Pos_2: 'larimar',
    Pos_3: 'honglan-peerless',
    Pos_4: 'lilith',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Assault',
    Mode: 'Guild Raid Charite',
    Treasure: 'Arrowhead/ Cursed Sword/ Glass Cannon',
    Explanation:
      '{13-Mar-2025} Quick Job anomaly active. KR public comp - PHonglan variant',
    Subs: 'Ult: CMephi ult, PHonglan ult within last 60 seconds depending on your artifact level.',
    Gear: 'Gear: CMephi - CDMG, Rest Speed'
  },
  {
    Id: 3,
    Pos_1: 'yuria',
    Pos_2: 'larimar',
    Pos_3: 'mephistopeles',
    Pos_4: 'lilith',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Assault',
    Mode: 'Guild Raid Charite',
    Treasure: 'Arrowhead/ Cursed Sword/ Glass Cannon',
    Explanation:
      '{19-Mar-2025} Quick Job anomaly active. KR public comp - Mephistopheles variant',
    Subs: 'Ult: CMephi Ult x3 (Till she gains 10 stacks), then alternate between CMephi and Lilith Ult until you struggle to survive, then switch to Yuria Ult.',
    Gear: 'Gear: CMephi - CDMG, Rest Speed'
  },
  {
    Id: 4,
    Pos_1: 'honglan-peerless',
    Pos_2: 'lilith',
    Pos_3: 'larimar',
    Pos_4: 'mephistopeles',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Assault',
    Mode: 'Guild Raid Charite',
    Treasure: 'Arrowhead/ Cursed Sword/ Glass Cannon',
    Explanation:
      '{19-Mar-2025} Quick Job anomaly active. KR public comp - Mephistopheles + Phonglan variant',
    Subs: 'Ult: CMephi Ult x3 (Till she gains 10 stacks), PHonglan ult within last 60 seconds depending on your artifact level. then alternate between CMephi and Lilith Ult.',
    Gear: 'Gear: CMephi - CDMG, Rest Speed'
  },
  {
    Id: 5,
    Pos_1: 'garnet',
    Pos_2: 'honglan-peerless',
    Pos_3: 'onyx',
    Pos_4: 'erusha',
    Pos_5: 'hazel',
    Formation: 'Defense',
    Mode: 'Guild Raid Charite',
    Treasure: 'Glass Cannon/ Cursed Sword',
    Explanation:
      '{13-Mar-2025} Quick Job anomaly active. KR public comp - PHonglan variant',
    Subs: 'Ult: Hazel ult first, Erusha ult spam, PHonglan ult within last 60 seconds depending on artifact level',
    Gear: 'Gear: Hazel, Erusha - CDMG, rest Speed'
  },
  {
    Id: 6,
    Pos_1: 'garnet',
    Pos_2: 'wheri',
    Pos_3: 'onyx',
    Pos_4: 'erusha',
    Pos_5: 'hazel',
    Formation: 'Defense',
    Mode: 'Guild Raid Charite',
    Treasure: 'Glass Cannon/ Cursed Sword',
    Explanation:
      '{13-Mar-2025} Quick Job anomaly active. KR public comp - Wheri variant',
    Subs: 'Ult: Hazel ult first, Erusha ult spam, alternate with Onyx ult if dying',
    Gear: 'Gear: Hazel, Erusha - CDMG, rest Speed'
  },
  {
    Id: 7,
    Pos_1: 'sakuyo-inferno',
    Pos_2: 'catherine-radiance',
    Pos_3: 'eve',
    Pos_4: 'sigrid',
    Pos_5: 'edith',
    Formation: 'Defense',
    Mode: 'Guild Raid Charite',
    Treasure: 'Mana Crystal',
    Explanation:
      '{13-Mar-2025} Quick Job anomaly active. KR public comp - Edith Sigrid variant',
    Subs: 'Ult: alternate between Sigrid and Edith ult',
    Gear: 'Gear: Edith, Sigrid - CDMG, rest Speed'
  },
  {
    Id: 8,
    Pos_1: 'sakuyo-inferno',
    Pos_2: 'catherine-radiance',
    Pos_3: 'eve',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'edith',
    Formation: 'Defense',
    Mode: 'Guild Raid Charite',
    Treasure: 'Glove',
    Explanation:
      '{13-Mar-2025} Quick Job anomaly active. KR public comp - CLinzy Sigrid variant',
    Subs: 'Ult: CLinzy',
    Gear: 'Gear: CLinzy  Sigrid - CDMG, rest Speed'
  },
  {
    Id: 9,
    Pos_1: 'eve',
    Pos_2: 'daphne',
    Pos_3: 'catherine-radiance',
    Pos_4: 'yuria',
    Pos_5: 'claudia',
    Formation: 'Assault',
    Mode: 'Eden Alliance - Tabria',
    Treasure: '',
    Explanation:
      'Objective: Survive for 1 minute. Enemies will be stunned for a duration of time when defeated before restoring to full HP. They will not die.',
    Subs: 'Ult: Claudia ult to kill, Yuria ult if dying. ',
    Gear: ''
  },
  {
    Id: 10,
    Pos_1: 'daphne',
    Pos_2: 'linzy',
    Pos_3: 'honglan',
    Pos_4: 'yuria',
    Pos_5: 'larimar',
    Formation: 'Ranged',
    Mode: 'Eden Alliance - Tabria',
    Treasure: '',
    Explanation:
      'Objective: Survive for 1 minute. Enemies will be stunned for a duration of time when defeated before restoring to full HP. They will not die.',
    Subs: 'Ult: Honglan.',
    Gear: ''
  },
  {
    Id: 11,
    Pos_1: 'tasha',
    Pos_2: 'sakuyo',
    Pos_3: 'haru',
    Pos_4: 'lute',
    Pos_5: 'onyx',
    Formation: 'Assault',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation: '{13-Mar-2025} Fairy Typing, Debuff/ Beast favored',
    Subs: 'Ult: Tasha',
    Gear: 'Gear: Sakuyo, Tasha, Lute - CDMG, rest Speed'
  },
  {
    Id: 12,
    Pos_1: 'yuria',
    Pos_2: 'mephistopheles-dawn',
    Pos_3: 'larimar',
    Pos_4: 'catherine-radiance',
    Pos_5: 'sakuyo',
    Formation: 'Assault',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation:
      '{13-Mar-2025} Fairy Typing, Debuff/ Beast favored. Sakuyo can be exchanged for Tasha, Sakuyo Inferno',
    Subs: 'Ult: Sakuyo. Get boss down to 75% to remove auto-cleanse mechanic.',
    Gear: ''
  },
  {
    Id: 13,
    Pos_1: 'yuria',
    Pos_2: 'mephistopheles-dawn',
    Pos_3: 'larimar',
    Pos_4: 'xiaolian',
    Pos_5: 'sakuyo-inferno',
    Formation: 'Defense',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation:
      '{13-Mar-2025} Fairy Typing, Debuff/ Beast favored. Beast can be exchanged for Tasha or Sakuyo in some cases.',
    Subs: 'Ult: Xiaolian. Get boss down to 75% to remove auto-cleanse mechanic.',
    Gear: ''
  },
  {
    Id: 14,
    Pos_1: 'tasha',
    Pos_2: 'sakuyo',
    Pos_3: 'haru',
    Pos_4: 'honglan-peerless',
    Pos_5: 'onyx',
    Formation: 'Defense',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation:
      '{13-Mar-2025} Fairy Typing, Debuff/ Beast favored. DPS is interchangeable (Sakuyo-inferno, Xiaolian)',
    Subs: 'Ult: Tasha ult, PHonglan in last 1 minute depending on your artifact level',
    Gear: ''
  },
  {
    Id: 15,
    Pos_1: 'yuria',
    Pos_2: 'mephistopheles-dawn',
    Pos_3: 'larimar',
    Pos_4: 'honglan-peerless',
    Pos_5: 'sakuyo',
    Formation: 'Assault',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation:
      '{13-Mar-2025} Fairy Typing, Debuff/ Beast favored. DPS is interchangeable (Tasha, Sakuyo-inferno, Xiaolian)',
    Subs: 'Ult: Sakuyo ult, PHonglan in last 1 minute depending on your artifact level',
    Gear: ''
  },
  {
    Id: 16,
    Pos_1: 'daphne',
    Pos_2: 'yuria',
    Pos_3: 'eve',
    Pos_4: 'flex',
    Pos_5: 'flex',
    Formation: 'Ranged',
    Mode: 'Battlefront',
    Treasure: '',
    Explanation:
      'The standard Hyper Carry Team to bulldoze through 1 Team Content. Best carry (flex 2, pos 5) options: Edith, Mephistopheles (Dawn), Linzy (Thanatos), Aki.',
    Subs: 'Substitutions: Replace tank/healer with tank/healer from secondary/tertiary teams. Flex 1: CC/Buffer/SubDPS slot. See tier list for best options.',
    Gear: ''
  },
  {
    Id: 17,
    Pos_1: 'chloe',
    Pos_2: 'beleth',
    Pos_3: 'honglan',
    Pos_4: 'flex',
    Pos_5: 'flex',
    Formation: 'Basic',
    Mode: 'Battlefront',
    Treasure: '',
    Explanation:
      'Secondary/tertiary BF team for 3 Team Content. Best carry options (flex 2, pos 5): same as Team 1 plus Lizelotte, Claudia,Catherine (Radiance), Sigrid.',
    Subs: 'Substitions: Replace tank/healer with next best option from tertiary team. Honglan -> Ayame/Naiah. Flex 1: CC/Buffer/SubDPS slot. See tier list for best filler options.',
    Gear: ''
  },
  {
    Id: 18,
    Pos_1: 'petra',
    Pos_2: 'wheri',
    Pos_3: 'larimar',
    Pos_4: 'flex',
    Pos_5: 'flex',
    Formation: 'Basic',
    Mode: 'Battlefront',
    Treasure: '',
    Explanation:
      'Secondary/tertiary BF team for 3 Team Content. Best carry options (flex 2, pos 5): same as previous, plus Hazel, Ayame, Linzy, Erusha, Velanna.',
    Subs: 'Substitution tanks: Claire, Soonie. Healers: Jiho, Catherine, Prim. Flex 1: CC/Buffer/SubDPS slot. See tier list for best filler options.',
    Gear: ''
  },
  {
    Id: 19,
    Pos_1: 'jiho',
    Pos_2: 'catherine',
    Pos_3: 'aira',
    Pos_4: 'honglan',
    Pos_5: 'aki',
    Formation: 'Assault',
    Mode: 'Boss - Labyrinth',
    Treasure: '',
    Explanation:
      "Your goal is to keep Chamuel's mana from filling. Use Cath ultimate on 2nd & 3rd dash attacks for survival",
    Subs: 'Ult: Honglan, Jiho, or Cath (depends on treasures)',
    Gear: 'Gear: All - Speed'
  },
  {
    Id: 20,
    Pos_1: 'claire',
    Pos_2: 'yuria',
    Pos_3: 'lizelotte',
    Pos_4: 'eve',
    Pos_5: '',
    Formation: 'Ranged',
    Mode: 'Eden Alliance - Gaon',
    Treasure: '',
    Explanation:
      '{01-Dec-2024} Clears max score for this Operation. If enemy Aki uses main skill, Lizelotte must survive or you restart. Level 350~400+ Sync clear.',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG or Anni Attack, Rest - Speed'
  },
  {
    Id: 21,
    Pos_1: 'edith',
    Pos_2: 'sigrid',
    Pos_3: 'talia',
    Pos_4: 'onyx',
    Pos_5: 'wheri',
    Formation: 'Ranged',
    Mode: 'Eden Alliance - Troyca',
    Treasure: '',
    Explanation: '{27-Jan-2025} Clears max score for this Operation',
    Subs: "Ult: Edith - Onxy ult if you're struggling to survive",
    Gear: 'Gear: Edith, Sigrid - CDMG; Rest - Speed'
  },
  {
    Id: 22,
    Pos_1: 'eve',
    Pos_2: 'catherine-radiance',
    Pos_3: 'yuria',
    Pos_4: 'larimar',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Defense',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation:
      '{06-Jan-2025} Ensure Mephi gets Eve Bond, Keep Larimar HP low. Credits to Zigg (Arca) Video Link - https://youtu.be/h1EU35U_ipg',
    Subs: 'Ult: Mephistopheles (Dawn)',
    Gear: 'Gear: Mephistopheles (Dawn) - Attack , Rest - Speed'
  },
  {
    Id: 23,
    Pos_1: 'wheri',
    Pos_2: 'larimar',
    Pos_3: 'eve',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation:
      '{06-Jan-2025} Ensure Linzy gets Eve Bond. Credits to 맑지않음 (Arca) Video Link - https://youtu.be/ch14oUYJAZE',
    Subs: 'Ult - Wheri, save Linzy (Thantos) Ult for the end (or as late as possible before dying)',
    Gear: 'Gear: Mephistopheles (Dawn) - Attack , Rest - Speed'
  },
  {
    Id: 24,
    Pos_1: 'yuria',
    Pos_2: 'eve',
    Pos_3: 'larimar',
    Pos_4: 'catherine-radiance',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation:
      '{06-Jan-2025} Ensure Linzy gets Eve Bond. Credits to ㅇㅇㅋ (Arca) Video Link - https://youtu.be/vjA3RdaX1o8',
    Subs: 'Ult - Eve first, Larimar once-twice, Yuria for suvival and save Linzy (Thantos) Ult for the end (or as late as possible before dying)',
    Gear: 'Gear: Linzy (Thanatos) - CDMG, Rest - Speed'
  },
  {
    Id: 25,
    Pos_1: 'yuria',
    Pos_2: 'eve',
    Pos_3: 'catherine-radiance',
    Pos_4: 'talia',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation: 'Alternate Adrianne ESS comp',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 26,
    Pos_1: 'yuria',
    Pos_2: 'beleth',
    Pos_3: 'eve',
    Pos_4: 'lizelotte',
    Pos_5: 'catherine-radiance',
    Formation: 'Defense',
    Mode: 'ESS Adrianne',
    Treasure: '',
    Explanation: 'Best Single Team clear comp for Adrianne ESS.',
    Subs: 'Ult: Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 27,
    Pos_1: 'larimar',
    Pos_2: 'dominique',
    Pos_3: 'hazel',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'yuria',
    Formation: 'Ranged',
    Mode: 'ESS Aira',
    Treasure: '',
    Explanation: '{27-Jan-2025} Clears level 800 with correct mechanic play.',
    Subs: 'Ult: Hazel -> Mephi-Dawn -> Dominique -> Yuria -> Larimar\nAdjust Yuria according to your survival needs',
    Gear: 'Gear: Hazel - 2pc CDMG 2pc ATK (slot 3 or 4 is Anni ATK for the lifesteal); Dominique - ATK (slots 3 and 4 are Anni ATK for the lifesteal); Mephi-Dawn - CDMG; Rest - Speed'
  },
  {
    Id: 28,
    Pos_1: 'aira',
    Pos_2: 'onyx',
    Pos_3: 'sigrid',
    Pos_4: 'haru',
    Pos_5: 'tasha',
    Formation: 'Ranged',
    Mode: 'ESS Aira',
    Treasure: '',
    Explanation: '{27-Jan-2025} Clears level 800 with correct mechanic play.',
    Subs: 'Ult: Aira -> Sigrid -> Haru -> Onyx -> Tasha\nAdjust Onyx according to your survival needs',
    Gear: 'Gear: Aira, Onyx - Speed; Rest - CDMG'
  },
  {
    Id: 29,
    Pos_1: 'dora',
    Pos_2: 'catherine-radiance',
    Pos_3: 'wheri',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'otoha',
    Formation: 'Defense',
    Mode: 'ESS Aira',
    Treasure: '',
    Explanation:
      "{27-Jan-2025} Clean up comp if the other two can't get the job done.",
    Subs: 'Ult: Dora -> Linzy-Thanatos -> Otoha -> Wheri\nAdjust Otoha ult as need. Only use when Aira is 3% HP or less',
    Gear: 'Gear: Dora, Otoha - ATK (slots 1 and 2 are Anni ATK); Linzy-Thanatos - CDMG; Wheri - HP; Catherine-Radiance - Speed'
  },
  {
    Id: 30,
    Pos_1: 'eve',
    Pos_2: 'naiah',
    Pos_3: 'larimar',
    Pos_4: 'yuria',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'ESS Aira',
    Treasure: '',
    Explanation:
      'Best Single Team clear comp for Aira ESS. Naiah allows you to enter groggy faster',
    Subs: 'Ult - Lizelotte',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed'
  },
  {
    Id: 31,
    Pos_1: 'larimar',
    Pos_2: 'yuria',
    Pos_3: 'honglan-peerless',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'aki',
    Formation: 'Defense',
    Mode: 'ESS Aki',
    Treasure: '',
    Explanation:
      '{8 March 2025} Causality Season - Aki was buffed for this season and can remove Bloodwind Stacks while removing a large amount of Daze. Public KR Comp, formation/ pattern unknown.',
    Subs: '',
    Gear: ''
  },
  {
    Id: 32,
    Pos_1: 'wheri',
    Pos_2: 'onyx',
    Pos_3: 'garnet',
    Pos_4: 'sigrid',
    Pos_5: 'otoha',
    Formation: 'Defense',
    Mode: 'ESS Aki',
    Treasure: '',
    Explanation:
      '{8 March 2025} Causality Season - Otoha was buffed for this season and will deal bonus damage while having a 5% HP execution threshold. Public KR Comp, formation/ pattern unknown.',
    Subs: '',
    Gear: ''
  },
  {
    Id: 33,
    Pos_1: 'mephistopeles',
    Pos_2: 'yuria',
    Pos_3: 'larimar',
    Pos_4: 'dominique',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'ESS Ayame',
    Treasure: '',
    Explanation:
      'Alternate Ayame ESS Team without Lilith. Requires decently high investment',
    Subs: 'Ult - Dominique, Clinzy',
    Gear: 'Gear: Linzy (Thantos) & Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 34,
    Pos_1: 'mephistopeles',
    Pos_2: 'yuria',
    Pos_3: 'lilith',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'larimar',
    Formation: 'Basic',
    Mode: 'ESS Ayame',
    Treasure: '',
    Explanation: 'Alternate Ayame ESS comp',
    Subs: 'UIt - Lilith, Clinzy.',
    Gear: 'Gear: Linzy (Thantos) - CDMG, Rest - Speed.'
  },
  {
    Id: 35,
    Pos_1: 'mephistopeles',
    Pos_2: 'lilith',
    Pos_3: 'larimar',
    Pos_4: 'yuria',
    Pos_5: 'dominique',
    Formation: 'Assault',
    Mode: 'ESS Ayame',
    Treasure: '',
    Explanation: 'Best Single Team clear comp for Ayame ESS.',
    Subs: 'Ult - Dominique',
    Gear: 'Gear: Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 36,
    Pos_1: 'larimar',
    Pos_2: 'catherine-radiance',
    Pos_3: 'lilith',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'rose-prominence',
    Formation: 'Defense',
    Mode: 'ESS Catherine',
    Treasure: '',
    Explanation: '{17-Feb-2025} Manual comp for difficulty 800',
    Subs: 'Ult: Mephi (Dawn) -> Rose (Prominence) -> Lilith -> Larimar',
    Gear: 'Gear: Rose (Prominence), Mephi (Dawn) - CDMG, Rest - Speed'
  },
  {
    Id: 37,
    Pos_1: 'larimar',
    Pos_2: 'catherine-radiance',
    Pos_3: 'catherine',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'rose-prominence',
    Formation: 'Basic',
    Mode: 'ESS Catherine',
    Treasure: '',
    Explanation:
      '{17-Feb-2025} Autoskip comp for difficulty 700 or below (depending on investment)',
    Subs: 'Ult: Mephi (Dawn) -> Rose (Prominence) -> Catherine -> Larimar\nDo not use Catherine (Radiance) ult at all',
    Gear: 'Gear: Mephi (Dawn) - CDMG, Rose (Prominence) - ATK, Catherine - HP, Rest - Speed'
  },
  {
    Id: 38,
    Pos_1: 'eve',
    Pos_2: 'kanna',
    Pos_3: 'larimar',
    Pos_4: 'mephistopeles',
    Pos_5: 'dominique',
    Formation: 'Assault',
    Mode: 'ESS Jacqueline',
    Treasure: '',
    Explanation: 'Best Single Team clear comp for Jacqueline ESS.',
    Subs: 'Ult - Dominique',
    Gear: 'Gear: Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 39,
    Pos_1: 'yuria',
    Pos_2: 'eve',
    Pos_3: 'sigrid',
    Pos_4: 'beleth',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'ESS Lizelotte',
    Treasure: '',
    Explanation: 'Best Single Team clear comp for Lizelotte ESS.',
    Subs: 'Ult: Sigrid, Clinzy',
    Gear: 'Gear: Linzy (Thantos) & Sigrid - CDMG, Rest - Speed'
  },
  {
    Id: 40,
    Pos_1: 'onyx',
    Pos_2: 'haru',
    Pos_3: 'melfice',
    Pos_4: 'tasha',
    Pos_5: 'bryce',
    Formation: 'Defense',
    Mode: 'ESS Lizelotte',
    Treasure: '',
    Explanation: 'Additional Team for Lizelotte ESS if needed.',
    Subs: 'Ult: Bryce',
    Gear: 'Gear: Onyx - Speed, Rest - CDMG'
  },
  {
    Id: 41,
    Pos_1: 'yuria',
    Pos_2: 'larimar',
    Pos_3: 'dominique',
    Pos_4: 'hazel',
    Pos_5: 'erusha',
    Formation: 'Defense',
    Mode: 'ESS Naiah',
    Treasure: '',
    Explanation:
      "{16-Dec-2024} Take note that this team/explanation is for ESS 501+ and may not work below 501. Larimar must be highest ATK so that she takes the prison when fight starts. Yuria's main will cleanse 15 stress stacks each time, so try to time it so that you keep above 30 but below 70 stress stacks. Aside from that, you basically just spam skills as they come up while making sure you don't cancel Hazel or Erusha's stun subs, or Yuria's sub 2.",
    Subs: 'Utl: Hazel first, then Erusha remainder of fight',
    Gear: 'Gear: Hazel, Erusha, Dominique - CDMG, Yuria - Speed, Larimar - ATK or ATK + HP (must be highest ATK on team)'
  },
  {
    Id: 42,
    Pos_1: 'larimar',
    Pos_2: 'yuria',
    Pos_3: 'hazel',
    Pos_4: 'lilith',
    Pos_5: 'erusha',
    Formation: 'Basic',
    Mode: 'ESS Vivienne',
    Treasure: '',
    Explanation:
      'Best Single Team clear comp for current Vivienne ESS iteration. ',
    Subs: 'Ult: Hazel first, then Erusha for the remainder of fight',
    Gear: 'Gear:Hazel, Erusha - CDMG, Rest - Speed'
  },
  {
    Id: 43,
    Pos_1: 'garnet',
    Pos_2: 'violette',
    Pos_3: 'onyx',
    Pos_4: 'dominique',
    Pos_5: 'erusha',
    Formation: 'Assault',
    Mode: 'ESS Vivienne',
    Treasure: '',
    Explanation:
      'Autoskip 600 team for current Vivienne ESS iteration (Sync Dependent)',
    Subs: 'Ult: Onyx (can use Erusha if sync is high enough)',
    Gear: 'Gera: Erusha, Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 44,
    Pos_1: 'clara',
    Pos_2: 'seeha',
    Pos_3: 'mica',
    Pos_4: 'flex',
    Pos_5: 'honglan',
    Formation: 'Defense',
    Mode: 'Gate Beast',
    Treasure: '',
    Explanation: 'Best team for single team content (Level 1-200 Faction Gate)',
    Subs: 'Beast flex options: Flynn, Haru, Xiaolian, Tasha, Lute',
    Gear: ''
  },
  {
    Id: 45,
    Pos_1: 'soonie',
    Pos_2: 'onyx',
    Pos_3: 'sakuyo',
    Pos_4: 'flex',
    Pos_5: 'flex',
    Formation: 'Basic',
    Mode: 'Gate Beast',
    Treasure: '',
    Explanation: 'Secondary team for Faction Depths.',
    Subs: '',
    Gear: ''
  },
  {
    Id: 46,
    Pos_1: 'daphne',
    Pos_2: 'talia',
    Pos_3: 'flex',
    Pos_4: 'naiah',
    Pos_5: 'dominique',
    Formation: 'Basic',
    Mode: 'Gate Fairy',
    Treasure: '',
    Explanation: 'Best team for single team content (Level 1-200 Faction Gate)',
    Subs: 'Best Fairy 1 team flex options: Vivi, Manon, Nicole',
    Gear: ''
  },
  {
    Id: 47,
    Pos_1: 'chloe',
    Pos_2: 'erika',
    Pos_3: 'vivienne',
    Pos_4: 'flex',
    Pos_5: 'nicole',
    Formation: 'Defense',
    Mode: 'Gate Fairy',
    Treasure: '',
    Explanation: 'Secondary team for Faction Depths.',
    Subs: 'Best Fairy supplementary flex options: Edith, Miriam/Kanna',
    Gear: ''
  },
  {
    Id: 48,
    Pos_1: 'claire',
    Pos_2: 'beleth',
    Pos_3: 'mephistopeles',
    Pos_4: 'flex',
    Pos_5: 'aki',
    Formation: 'Basic',
    Mode: 'Gate Human',
    Treasure: '',
    Explanation: 'Best team for single team content (Level 1-200 Faction Gate)',
    Subs: '',
    Gear: ''
  },
  {
    Id: 49,
    Pos_1: 'linzy',
    Pos_2: 'catherine',
    Pos_3: 'jiho',
    Pos_4: 'dora',
    Pos_5: 'naomi',
    Formation: 'Basic',
    Mode: 'Gate Human',
    Treasure: '',
    Explanation: 'Secondary team for Faction Depths.',
    Subs: '',
    Gear: ''
  },
  {
    Id: 50,
    Pos_1: 'joanne',
    Pos_2: 'bryce',
    Pos_3: 'flex',
    Pos_4: 'rebecca',
    Pos_5: 'sigrid',
    Formation: 'Basic',
    Mode: 'Gate Undead',
    Treasure: '',
    Explanation: 'Secondary team for Faction Depths.',
    Subs: 'Undead flex options: Otoha, Nini',
    Gear: ''
  },
  {
    Id: 51,
    Pos_1: 'petra',
    Pos_2: 'prim',
    Pos_3: 'jacqueline',
    Pos_4: 'garnet',
    Pos_5: 'velanna',
    Formation: 'Basic',
    Mode: 'Gate Undead',
    Treasure: '',
    Explanation: 'Best team for single team content (Level 1-200 Faction Gate)',
    Subs: '',
    Gear: ''
  },
  {
    Id: 52,
    Pos_1: 'yuria',
    Pos_2: 'nini',
    Pos_3: 'larimar',
    Pos_4: 'lilith',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Assault',
    Mode: 'Guild Raid - Chloe (Throwback)',
    Treasure: 'Obsidian Arrowhead',
    Explanation: '{03-Feb-2025} Straightforward auto comp',
    Subs: "Ult: Mephistopheles (Dawn) all the way. You can alternate Mephi and Lilith if you want, but results may vary. Use Yuria ult if you're struggling to survive.",
    Gear: 'Gear: Mephistopheles (Dawn) - CDMG or ATK, Rest - Speed'
  },
  {
    Id: 53,
    Pos_1: 'joanne',
    Pos_2: 'garnet',
    Pos_3: 'onyx',
    Pos_4: 'hazel',
    Pos_5: 'erusha',
    Formation: 'Defense',
    Mode: 'Guild Raid - Chloe (Throwback)',
    Treasure: 'Glass Cannon',
    Explanation: '{03-Feb-2025} Semi-auto comp',
    Subs: 'Ult: Joanne first, then Hazel second, then you can alternate Erusha and Onyx based on survival needs',
    Gear: 'Gear: Erusha - CDMG; Hazel - ATK (slot 3 or 4 should be Anni ATK); Rest - Speed'
  },
  {
    Id: 54,
    Pos_1: 'rebecca',
    Pos_2: 'wheri',
    Pos_3: 'kanna',
    Pos_4: 'dominique',
    Pos_5: 'weiss',
    Formation: 'Basic',
    Mode: 'Guild Raid - Chloe (Throwback)',
    Treasure: 'Blue Mana Crystal',
    Explanation: '{03-Feb-2025} Semi-auto comp',
    Subs: 'Ult: Alternate Kanna and Weiss',
    Gear: 'Gear: Weiss, Dominique - ATK (slot 3 or 4 should be Anni ATK); Rest - Speed; Wheri can be speed or hp, results vary'
  },
  {
    Id: 55,
    Pos_1: 'sigrid',
    Pos_2: 'wheri',
    Pos_3: 'garnet',
    Pos_4: 'joanne',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'Guild Raid - Chloe (Throwback)',
    Treasure: 'Glass Cannon',
    Explanation: '{03-Feb-2025} Semi-auto alternative comp',
    Subs: 'Ult: Joanne first, Sigrid remainder until end, Linzy ult for final ult. If struggling to survive, alternate Wheri and Sigrid',
    Gear: 'Gear: Sigrid, Linzy (Thanatos) - CDMG, Rest - Speed'
  },
  {
    Id: 56,
    Pos_1: 'rebecca',
    Pos_2: 'onyx',
    Pos_3: 'violette',
    Pos_4: 'kanna',
    Pos_5: 'weiss',
    Formation: 'Basic',
    Mode: 'Guild Raid - Chloe (Throwback)',
    Treasure: 'Blue Mana Crystal',
    Explanation: '{03-Feb-2025} Semi-auto alternative comp',
    Subs: "Ult: Alternate Kanna and Weiss. Onyx ult if you're struggling to survive",
    Gear: 'Gear: Violette, Weiss - ATK (slots 3/4 Anni ATK); Kanna - CDMG or Speed (results vary); Rest - Speed'
  },
  {
    Id: 57,
    Pos_1: 'catherine-radiance',
    Pos_2: 'larimar',
    Pos_3: 'sigrid',
    Pos_4: 'kanna',
    Pos_5: 'yuria',
    Formation: 'Basic',
    Mode: 'Guild Raid Dark Knight',
    Treasure: "Falcon Hunter's Gloves",
    Explanation:
      "{01-Dec-2024} As long as you have 4pc speed on Yuria then her shield timings should line up to prevent stun. If it's not lining up, you can use Cath's shield as a backup. Larimar's sentence is not that important here, but her debuff spreading is. If you're playing on manual, make sure you don't cancel her Sub2 ability.",
    Subs: "Ult: Sigrid ult mostly. If you're dying after the first mob wave ends, try using Yuria ult just before that. Swap back to Sig ult afterwards and watch HP as you may need to use Yuria ult again. Subs: Ayame/Nicole instead Larimar.",
    Gear: 'Gear: Sigrid - ATK, Rest - Speed (Sigrid needs higher ATK than Kanna)'
  },
  {
    Id: 58,
    Pos_1: 'onyx',
    Pos_2: 'lilith',
    Pos_3: 'soonie',
    Pos_4: 'erusha',
    Pos_5: 'wheri',
    Formation: 'Defense',
    Mode: 'Guild Raid Dark Knight',
    Treasure: 'Glass Cannon',
    Explanation:
      "{01-Dec-2024} Wheri's shield should line up to block the stun after first mob wave. Use Lilith Main before using Erusha Main/Ult if possible.",
    Subs: 'Ult: Erusha only (most cases). You can try using Onyx ult just before each mob wave starts. Increases damage for some people.',
    Gear: 'Gear: Erusha - CDMG, Rest - Speed'
  },
  {
    Id: 59,
    Pos_1: 'melfice',
    Pos_2: 'bryce',
    Pos_3: 'linzy-thanatos',
    Pos_4: 'petra',
    Pos_5: 'garnet',
    Formation: 'Ranged',
    Mode: 'Guild Raid Dark Knight',
    Treasure: 'Cursed Sword',
    Explanation:
      "{01-Dec-2024} If playing manually, tick the auto button when it's time for Petra to use her main skill so that you don't throw off her shield timings.",
    Subs: "Ult: Alternate Bryce and Melfice. Use Linzy's ult at the end",
    Gear: "Gear: Linzy (Thantos), Bryce - CDMG, Melfice - HP, Rest - Speed (if Melfice isn't having survival issues then swap to speed)"
  },
  {
    Id: 60,
    Pos_1: 'catherine-radiance',
    Pos_2: 'yuria',
    Pos_3: 'wheri',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'sigrid',
    Formation: 'Basic',
    Mode: 'Guild Raid Dark Knight',
    Treasure: 'Cursed Sword',
    Explanation:
      '{01-Dec-2024} No special explanation needed. Basically an auto comp. ',
    Subs: "Ult: Sigrid until the end and then use Linzy. Yuria ult just before first mob wave ends if you're having survival issues.",
    Gear: 'Gear: Sigrid, Linzy (Thanatos) - CDMG, Rest - Speed'
  },
  {
    Id: 61,
    Pos_1: 'onyx',
    Pos_2: 'dominique',
    Pos_3: 'daphne',
    Pos_4: 'erusha',
    Pos_5: 'lilith',
    Formation: 'Defense',
    Mode: 'Guild Raid Dark Knight',
    Treasure: 'Glass Cannon',
    Explanation:
      '{01-Dec-2024} Use Lilith Main before using Erusha Main/Ult if possible.',
    Subs: 'Ult: Erusha only (most cases). You can try using Onyx ult just before each mob wave starts. Increases damage for some people. Sub:  Soonie can be used instead Daphne.',
    Gear: 'Gear: Erusha, Dominique - CDMG, Rest - Speed (if your Dominique is having survival issues, switch her to 2pc ATK 2pc CDMG, but make sure either slot 3 or 4 has an Anni ATK  piece in it for lifesteal)'
  },
  {
    Id: 62,
    Pos_1: 'soonie',
    Pos_2: 'claudia',
    Pos_3: 'beleth',
    Pos_4: 'hazel',
    Pos_5: 'eve',
    Formation: 'Basic',
    Mode: 'Guild Raid Dark Knight',
    Treasure: "Falcon Hunter's Gloves",
    Explanation:
      "{01-Dec-2024} Hazel must have the highest ATK so that she gets Eve's buff",
    Subs: 'Ult: Hazel Sub: Daphne can be used instead Soonie. Violette can replace Claudia (but lower damage) for pairing with Hazel.',
    Gear: 'Gear: Hazel - ATK, Claudia - CDMG, Rest - Speed (if your Daphne is having survival issues, switch her to 4pc HP or DEF)'
  },
  {
    Id: 63,
    Pos_1: 'catherine-radiance',
    Pos_2: 'ayame',
    Pos_3: 'larimar',
    Pos_4: 'yuria',
    Pos_5: 'sakuyo-inferno',
    Formation: 'Defense',
    Mode: 'Guild Raid Gaia',
    Treasure: 'Cursed Sword',
    Explanation:
      '{20-Dec-2024} Catherine must be highest HP to take shackle. Sakuyo must be highest ATK.',
    Subs: "Ult: Sakuyo-Inferno | Use Sakuyo if you don't have Sakuyo-Inferno",
    Gear: 'Gear: Sakuyo (Inferno) - CDMG or ATK, Catherine (Radiance) - Speed or HP, Rest - Speed'
  },
  {
    Id: 64,
    Pos_1: 'cherrie',
    Pos_2: 'beleth',
    Pos_3: 'garnet',
    Pos_4: 'eve',
    Pos_5: 'sigrid',
    Formation: 'Defense',
    Mode: 'Guild Raid Gaia',
    Treasure: "Falcon Hunter's Glove",
    Explanation:
      '{20-Dec-2024} Beleth must be highest HP to take shackle. Sigrid must be highest ATK.',
    Subs: 'Ult: Sigrid',
    Gear: 'Sigrid - CDMG, Rest - Speed'
  },
  {
    Id: 65,
    Pos_1: 'onyx',
    Pos_2: 'aira',
    Pos_3: 'nini',
    Pos_4: 'erusha',
    Pos_5: 'dominique',
    Formation: 'Defense',
    Mode: 'Guild Raid Gaia',
    Treasure: 'Glass Cannon',
    Explanation: '{20-Dec-2024} Aira must be highest HP to take shackle.',
    Subs: 'Ult: Onyx ult full fight, or alternate between Onyx & Erusha ults',
    Gear: 'Erusha + Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 66,
    Pos_1: 'nini',
    Pos_2: 'beleth',
    Pos_3: 'eve',
    Pos_4: 'hazel',
    Pos_5: 'lilith',
    Formation: 'Defense',
    Mode: 'Guild Raid Gaia',
    Treasure: "Falcon Hunter's Glove",
    Explanation:
      '{23-Dec-2024} Beleth must be highest HP to take shackle. Hazel must be highest ATK.',
    Subs: 'Ult: Hazel ult first, then swap to Beleth ults till end',
    Gear: 'Gear: Hazel - CDMG or ATK, Rest - Speed (HP on Beleth if necessary)'
  },
  {
    Id: 67,
    Pos_1: 'joanne',
    Pos_2: 'dominique',
    Pos_3: 'onyx',
    Pos_4: 'garnet',
    Pos_5: 'erusha',
    Formation: 'Defense',
    Mode: 'Guild Raid Gaia',
    Treasure: 'Glass Cannon',
    Explanation: '{23-Dec-2024} Onyx must be highest HP to take shackle.',
    Subs: 'Ult: Joanne ult first, then swap to Erusha ults till end. ',
    Gear: 'Gear: Erusha, Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 68,
    Pos_1: 'catherine-radiance',
    Pos_2: 'ayame',
    Pos_3: 'yuria',
    Pos_4: 'lute',
    Pos_5: 'larimar',
    Formation: 'Basic',
    Mode: 'Guild Raid Gaia',
    Treasure: "Protector's Shield",
    Explanation:
      "{23-Dec-2024} Sakuyo (Inferno) replacement comp for those who don't have her. Cath (Radiance) must be highest HP to take shackle. Lute must be highest DEF.",
    Subs: 'Ult: Lute',
    Gear: 'Gear: Lute - CDMG, Rest - Speed'
  },
  {
    Id: 69,
    Pos_1: 'eve',
    Pos_2: 'rebecca',
    Pos_3: 'joanne',
    Pos_4: 'garnet',
    Pos_5: 'cherrie',
    Formation: 'Basic',
    Mode: 'Guild Raid Gaia',
    Treasure: "Falcon Hunter's Glove",
    Explanation:
      "{23-Dec-2024} Sigrid replacement comp for those who don't have her. Joanne must be highest HP to take shackle. High ascension on Eve artifact may be required for survival.",
    Subs: 'Ult: Joanne first, then swap to Cherrie ults till end.',
    Gear: "Gear: Cherrie - ATK, Rest - Speed (HP on Joanne if she's dying)"
  },
  {
    Id: 70,
    Pos_1: 'garnet',
    Pos_2: 'aira',
    Pos_3: 'vivienne',
    Pos_4: 'dominique',
    Pos_5: 'larimar',
    Formation: 'Defense',
    Mode: 'Guild Raid Gaia',
    Treasure: 'Lunatic Blade',
    Explanation:
      '{Old} High investment, no healer team. Aira cage tank. Aira/Larimar mana control, needs Origin arti or at least 2pc Spd to control mana well.',
    Subs: 'Ult: Dominique',
    Gear: 'Gear: Dominique - CDMG, Aira - Speed/HP, Rest - Speed | Investment: Aira Origin artifact (beneficial)'
  },
  {
    Id: 71,
    Pos_1: 'garnet',
    Pos_2: 'yuria',
    Pos_3: 'lilith',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'larimar',
    Formation: 'Basic',
    Mode: 'Guild Raid Pluto',
    Treasure: 'Obsidian Arrowhead',
    Explanation:
      "{12-Jan-2025} Main Team, does multiple times damege of other two teams combined. Works fine using full auto, or you can spam Mephi's main & ult for slightly higher score while letting auto handle the others.",
    Subs: 'Ult: Mephistopheles (Dawn) all the way, use Yuria on and off if close to dying\nSub: Garnet can be substituted by Dominique but will significantly reduce damage.  (Same formation)',
    Gear: 'Gear: Mephistopheles (Dawn) - CDMG or ATK, Rest - Speed | Mephi ideally wants Origin Artifact.'
  },
  {
    Id: 72,
    Pos_1: 'ayame',
    Pos_2: 'catherine-radiance',
    Pos_3: 'sigrid',
    Pos_4: 'beleth',
    Pos_5: 'linzy-thanatos',
    Formation: 'Assault',
    Mode: 'Guild Raid Pluto',
    Treasure: 'Falcon Hunters Gloves',
    Explanation:
      '{12-Jan-2025} Sigrid ult priority - Swap to Beleth ult at 50s mark (or just after) - After 2 Beleth ults, swap to Sig ult and then CLinzy ult for final hit before beam wipes you out. - Can spam Sigrid ult as it becomes available.',
    Subs: 'Ult: Sigrid, Beleth as needed, CLinzy at the end \nSub: Ayame can be substituted by Eve but witll reduce damage. (Same formation)',
    Gear: 'Gear: Linzy (Thanatos) - ATK, Sigrid- CDMG, Rest - Speed | Ayame Ideally wants Origin Artifact (If you can keep Clinzy highest attack with it)'
  },
  {
    Id: 73,
    Pos_1: 'dominique',
    Pos_2: 'wheri',
    Pos_3: 'onyx',
    Pos_4: 'hazel',
    Pos_5: 'erusha',
    Formation: 'Defense',
    Mode: 'Guild Raid Pluto',
    Treasure: 'Glass Cannon',
    Explanation:
      '{12-Jan-2025} Hazel ult first - Erusha ult until 50s mark, then swap to Onyx ult two times between 50s and 25s mark, then swap back to Erusha ult till end of fight. - Can spam Erusha ult as it becomes available. If dying, ignore Erusha ult and only use Onyx ult after the first Hazel ult.',
    Subs: 'Ult: Hazel, Erusha, Onyx as needed\nSub: If using Dominique in Mephi team, can use Kanna in her place here.  (Same formation)',
    Gear: 'Gear: Erusha - CDMG or ATK, Hazel - CDMG, Rest - Speed'
  },
  {
    Id: 74,
    Pos_1: 'beleth',
    Pos_2: 'melfice',
    Pos_3: 'tasha',
    Pos_4: 'sigrid',
    Pos_5: 'linzy-thanatos',
    Formation: 'Basic',
    Mode: 'Guild Raid Pluto',
    Treasure: 'Falcon Hunters Gloves',
    Explanation: '{12-Jan-2025} Worse than Ayame & Eve Team.',
    Subs: 'Ult: Sigrid all the way, use Beleth on and off if close to dying. Use Linzy (Thanatos) Ultimate at the end/as late as possible.',
    Gear: 'Gear: Linzy (Thanatos) - ATK, Sigrid- CDMG, Rest - Speed'
  },
  {
    Id: 75,
    Pos_1: 'catherine-radiance',
    Pos_2: 'yuria',
    Pos_3: 'sigrid',
    Pos_4: 'larimar',
    Pos_5: 'linzy-thanatos',
    Formation: 'Defense',
    Mode: 'Guild Raid Pluto',
    Treasure: 'Falcon Hunters Gloves',
    Explanation:
      " {Old} Build stacks on Linzy (Thanatos) and unleash huge Ult at the end, making sure Larimar's sentence is active to copy damage.",
    Subs: 'Ult: Sigrid until final rotation, then Linzy (Thantos)',
    Gear: 'Gear: Linzy (Thantos) - CDMG; Rest - Speed'
  },
  {
    Id: 76,
    Pos_1: 'yuria',
    Pos_2: 'larimar',
    Pos_3: 'lilith',
    Pos_4: 'garnet',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Assault',
    Mode: 'Guild Raid Rudra',
    Treasure: "Assassin's Claw",
    Explanation:
      '{24 Feb 2025} KR Public comp - Garnet variant. Notes: Blitzkreig Anomaly active.',
    Subs: "Ult: Mephi ult\nSub: If your Mephi-Dawn isn't invested, you can try replacing her with Linzy-Thanatos.",
    Gear: 'Gear: Mephi-Dawn CDMG/ Attack, rest - Speed'
  },
  {
    Id: 77,
    Pos_1: 'yuria',
    Pos_2: 'larimar',
    Pos_3: 'lilith',
    Pos_4: 'dominique',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Assault',
    Mode: 'Guild Raid Rudra',
    Treasure: "Assassin's Claw",
    Explanation:
      '{24 Feb 2025} KR Public comp - Dominique variant. Notes: Blitzkreig Anomaly active. Lilith Dominique may run at least 1 Mirror/ Key Anniversary piece for the lifesteal.',
    Subs: 'Ult: Mephi ult',
    Gear: 'Gear: Mephi-Dawn CDMG/ Attack, rest - Speed (Dominique Lilith 2 Speed 2 Attack (1 Anni piece) if dying'
  },
  {
    Id: 78,
    Pos_1: 'onyx',
    Pos_2: 'wheri',
    Pos_3: 'daphne',
    Pos_4: 'kanna',
    Pos_5: 'weiss',
    Formation: 'Defense',
    Mode: 'Guild Raid Rudra',
    Treasure: 'Cursed Sword',
    Explanation:
      '{24 Feb 2025} KR Public comp - Daphne/Garnet variant. Notes: Blitzkreig Anomaly active.',
    Subs: 'Ult: Kanna ult, use Onyx if dying\nSub: You can replace Daphne with Garnet to see which works better for you. Position remains the same.',
    Gear: 'Gear: Weiss CDMG, rest Speed.'
  },
  {
    Id: 79,
    Pos_1: 'rose-prominence',
    Pos_2: 'catherine-radiance',
    Pos_3: 'ayame',
    Pos_4: 'beleth',
    Pos_5: 'edith',
    Formation: 'Assault',
    Mode: 'Guild Raid Rudra',
    Treasure: 'Glass Cannon',
    Explanation:
      '{24 Feb 2025} KR Public comp - Ayame variant. Notes: Blitzkreig Anomaly active.',
    Subs: 'Ult: Edith ult\nSub: If your Ayame is low invest, try swapping her out for Eve. Position remains the same. Make sure Edith is highest ATK in this case.',
    Gear: 'Gear: Edith CDMG, rest Speed.'
  },
  {
    Id: 80,
    Pos_1: 'kanna',
    Pos_2: 'daphne',
    Pos_3: 'edith',
    Pos_4: 'yuria',
    Pos_5: 'weiss',
    Formation: 'Basic',
    Mode: 'Guild Raid Rudra',
    Treasure: 'Lunatic Blade',
    Explanation:
      '{24 Feb 2025} KR Public comp - Auto skip friendly. Notes: Blitzkreig Anomaly active.',
    Subs: 'Ult: Edith ult',
    Gear: 'Gear: Edith CDMG, rest Speed.'
  },
  {
    Id: 81,
    Pos_1: 'daphne',
    Pos_2: 'rose-prominence',
    Pos_3: 'catherine-radiance',
    Pos_4: 'dominique',
    Pos_5: 'beleth',
    Formation: 'Ranged',
    Mode: 'Guild Raid Rudra',
    Treasure: 'Glass Cannon',
    Explanation:
      '{24 Feb 2025} KR Public comp - copium Dominique carry. Notes: Blitzkreig Anomaly active. Can probably improve something with Freeze.',
    Subs: 'Ult: Dominique ult',
    Gear: 'Gear: Rose Attack (1 Mirror/ Key piece Anni set), Dominque Attack, rest Speed'
  },
  {
    Id: 82,
    Pos_1: 'ayame',
    Pos_2: 'daphne',
    Pos_3: 'beleth',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'sigrid',
    Formation: 'Basic',
    Mode: 'Guild Raid Rudra',
    Treasure: 'Glass Cannon',
    Explanation:
      '{24 Feb 2025} KR Public comp - copium Sigrid CLinzy comp. Notes: Blitzkreig Anomaly active. Can probably improve something',
    Subs: 'Ult: Sigrid ult, use Linzy ult if off CD',
    Gear: 'Gear: Sigrid & CLinzy Attack, rest Speed'
  },
  {
    Id: 83,
    Pos_1: 'onyx',
    Pos_2: 'weiss',
    Pos_3: 'edith',
    Pos_4: 'rose-prominence',
    Pos_5: 'kanna',
    Formation: 'Assault',
    Mode: 'Guild Raid Rudra',
    Treasure: 'Cursed Sword',
    Explanation:
      '{24 Feb 2025} KR Public comp - Edith Chalar gang comp. Notes: Blitzkreig Anomaly active.',
    Subs: 'Ult: Edith ult, use Onyx if dying',
    Gear: 'Gear: Edith CDMG, rest Speed.'
  },
  {
    Id: 84,
    Pos_1: 'beleth',
    Pos_2: 'larimar',
    Pos_3: 'garnet',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'sigrid',
    Formation: 'Defense',
    Mode: 'Infinite Raid',
    Treasure: '',
    Explanation: '{10 March 2025} Infinite Raid Honglan (Peerless) Comp',
    Subs: 'Ult - Sigrid spam with CLinzy nuke at last. Use Beleth ult if struggling to survive',
    Gear: 'Gear - CLinzy & Sigrid - CDMG, Rest - Speed'
  },
  {
    Id: 85,
    Pos_1: 'wheri',
    Pos_2: 'yuria',
    Pos_3: 'larimar',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Defense',
    Mode: 'Infinite Raid',
    Treasure: '',
    Explanation:
      '{Feb 24 2025} - Infinite Raid Rose (Prominence) | Public comp (Archived) - Linzy fulfils AOE bonus, Larimar fulfils caster bonus for score.',
    Subs: 'Ult: Mephi ult, Yuria ult + Wheri main if dying',
    Gear: 'Gear: Mephi CDMG, rest Speed.'
  },
  {
    Id: 86,
    Pos_1: 'beleth',
    Pos_2: 'yuria',
    Pos_3: 'larimar',
    Pos_4: 'eve',
    Pos_5: 'catherine-radiance',
    Formation: 'Assault',
    Mode: 'IR Cheese',
    Treasure: '',
    Explanation:
      '{01-Dec-2024} Team based on current/last iteration of the Boss Buff/Debuffs. Level 400~ Sync clear. If you are overhealing, can swap out Beleth with Linzy (Thanatos)',
    Subs: 'Ult: Catherine (Radiance) and then Beleth. If using Linzy (Thanatos) Ult: Catherine (Radiance), then Eve once and then Linzy (Thanatos)',
    Gear: 'Gear: Catherine (Radiance) - Anni Attack, Linzy (Thanatos) - CDMG,  Rest - Speed'
  },
  {
    Id: 87,
    Pos_1: 'yuria',
    Pos_2: 'lilith',
    Pos_3: 'mephistopheles-dawn',
    Pos_4: 'larimar',
    Pos_5: 'mephistopeles',
    Formation: 'Assault',
    Mode: 'IR Choco',
    Treasure: '',
    Explanation:
      "{24-Feb-2025} Turn on auto, but spam tap Mephi-Dawn's main skill",
    Subs: "Ult: Use Mephi-Dawn's ult 3 times and then swap to regular Mephi's ult until the end",
    Gear: 'Gear: Mephi-Dawn, Mephi - CDMG, Rest - Speed'
  },
  {
    Id: 88,
    Pos_1: 'mephistopeles',
    Pos_2: 'larimar',
    Pos_3: 'yuria',
    Pos_4: 'lilith',
    Pos_5: 'rose-prominence',
    Formation: 'Defense',
    Mode: 'IR Choco',
    Treasure: '',
    Explanation:
      '{24-Feb-2025} Team based on current/last iteration of Boss Buff/Debuffs',
    Subs: 'Ult: Lilith',
    Gear: 'Gear: Mephi - CDMG, Rose - ATK or Speed, Rest - Speed'
  },
  {
    Id: 89,
    Pos_1: 'yuria',
    Pos_2: 'dora',
    Pos_3: 'catherine-radiance',
    Pos_4: 'melfice',
    Pos_5: 'bryce',
    Formation: 'Defense',
    Mode: 'IR Cookie',
    Treasure: '',
    Explanation:
      '{27-Jan-2025} Team based on current/last iteration of Boss Buff/Debuffs',
    Subs: 'Ult: Alternate Bryce and Melfice\nSub: You can swap Dora to Garnet if necessary',
    Gear: 'Gear: Dora - Crit Rate; Melfice, Bryce - CDMG; Rest - Speed'
  },
  {
    Id: 90,
    Pos_1: 'yuria',
    Pos_2: 'catherine-radiance',
    Pos_3: 'wheri',
    Pos_4: 'eve',
    Pos_5: 'otoha',
    Formation: 'Assault',
    Mode: 'IR Cookie',
    Treasure: '',
    Explanation:
      '{27-Jan-2025} Team based on current/last iteration of Boss Buff/Debuffs',
    Subs: 'Ult: Otoha',
    Gear: 'Gear: Otoha - CDMG; Rest - Speed'
  },
  {
    Id: 91,
    Pos_1: 'yuria',
    Pos_2: 'wheri',
    Pos_3: 'catherine-radiance',
    Pos_4: 'rose-prominence',
    Pos_5: 'dominique',
    Formation: 'Defense',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation:
      '{17-Feb-2025} Team based on current/last iteration of the Boss Buff/Debuffs',
    Subs: "Ult: Rose (Prominence) or Dominique, whichever is more invested. Base Epic Rose (Prominence) works fine here.\nSub: You can swap Dominique to Nini if Dominique is underinvested. In this case, you'd only want to use Rose's ult.",
    Gear: 'Gear: Rose (Prominence) - ATK or CDMG, Dominique - CDMG, Rest - Speed'
  },
  {
    Id: 92,
    Pos_1: 'eve',
    Pos_2: 'wheri',
    Pos_3: 'yuria',
    Pos_4: 'catherine-radiance',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation: '{12-Jan-2025} Ensure Lizelotte gets Eve Bond.',
    Subs: 'Ult - Lizelotte all the way, if dying can use Yuria/Wheri ult as needed.',
    Gear: 'Gear: Lizelotte - CDMG, Catherine (Radiance) - Attack/Speed, Rest - Speed.'
  },
  {
    Id: 93,
    Pos_1: 'talia',
    Pos_2: 'wheri',
    Pos_3: 'yuria',
    Pos_4: 'eve',
    Pos_5: 'lizelotte',
    Formation: 'Assault',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation:
      '{12-Jan-2025} Ensure Lizelotte gets Eve Bond. Talia is there for passive Mana Recovery. Credits: Curren_chan from Arca',
    Subs: 'Ult - Lizelotte all the way, if dying can use Yuria/Wheri ult as needed.',
    Gear: 'Gear: Lizelotte - CDMG, Rest - Speed.'
  },
  {
    Id: 94,
    Pos_1: 'yuria',
    Pos_2: 'eve',
    Pos_3: 'beleth',
    Pos_4: 'larimar',
    Pos_5: 'kurumi-tokisaki',
    Formation: 'Assault',
    Mode: 'IR Tabby',
    Treasure: '',
    Explanation: '{12-Jan-2025} Ensure Kurumi gets Eve Bond. ',
    Subs: 'Ult - Kurumi all the way, if dying can use Yuria ult as needed.',
    Gear: 'Gear: Kurumi -  CDMG or ATK , Rest - Speed.'
  },
  {
    Id: 95,
    Pos_1: 'catherine-radiance',
    Pos_2: 'yuria',
    Pos_3: 'larimar',
    Pos_4: 'sakuyo',
    Pos_5: 'garnet',
    Formation: 'Ranged',
    Mode: 'IR Tofu',
    Treasure: '',
    Explanation:
      '{8 March 2025} Honglan (Peerless) debut week, Debuff type is buffed, Tofu is fairy typing for this iteration. Sakuyo slot can be any well built beast DPS (Tasha, Sakuyo (Inferno)) or Honglan (Peerless).',
    Subs: 'Ult: Beast DPS.',
    Gear: 'Gear: Main DPS - Attack or CDMG, Rest - Speed.'
  },
  {
    Id: 96,
    Pos_1: 'onyx',
    Pos_2: 'honglan-peerless',
    Pos_3: 'sakuyo',
    Pos_4: 'haru',
    Pos_5: 'tasha',
    Formation: 'Basic',
    Mode: 'IR Tofu',
    Treasure: '',
    Explanation:
      '{8 March 2025} Honglan (Peerless) debut week, Debuff type is buffed, Tofu is fairy typing for this iteration. Onyx Beast gang variant. ',
    Subs: 'Ult: Tasha.',
    Gear: 'Gear: DPS - Attack or CDMG, Rest - Speed.'
  },
  {
    Id: 97,
    Pos_1: 'yuria',
    Pos_2: 'larimar',
    Pos_3: 'honglan-peerless',
    Pos_4: 'mephistopheles-dawn',
    Pos_5: 'catherine-radiance',
    Formation: 'Basic',
    Mode: 'IR Tofu',
    Treasure: '',
    Explanation:
      '{8 March 2025} Honglan (Peerless) debut week, Debuff type is buffed, Tofu is fairy typing for this iteration. KR Public comp. Disclaimer - all but Phonglan have OG arti, Phonglan has Legend Arti. YMMV.',
    Subs: 'Ult Order - CCath > Phonglan > Dephi spam',
    Gear: 'Gear - Yuria &Phonglan - Speed, Dephi & CCath - Attack, Larimar - 2 HP 2 Speed. '
  },
  {
    Id: 98,
    Pos_1: 'wheri',
    Pos_2: 'lilith',
    Pos_3: 'onyx',
    Pos_4: 'hazel',
    Pos_5: 'dominique',
    Formation: 'Defense',
    Mode: 'World Raid Behemoth',
    Treasure: '',
    Explanation:
      'Partners: Wheri (Clara - Main) (Aira - Sub) ; Lilith (Larimar - Main) (Claudia - Sub) ; Onyx (Yuria - Main) (Mephistopheles - Sub) ; Dominique (Naiah - Main) (Kanna - Sub) ; Hazel (Lizelotte - Main) (Vivienne - Sub). If listed Subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: 'Ult:',
    Gear: 'Gear: Hazel - CDMG, Rest - Speed'
  },
  {
    Id: 99,
    Pos_1: 'violette',
    Pos_2: 'yuria',
    Pos_3: 'linzy-thanatos',
    Pos_4: 'larimar',
    Pos_5: 'joanne',
    Formation: 'Defense',
    Mode: 'World Raid Behemoth',
    Treasure: '',
    Explanation:
      'Partners: Linzy (Thanatos) (Otoha - Main) (Nini - Sub) ; Violette (Linzy - Main) (Eve - Sub) ; Yuria (Catherine - Main) (Lute - Sub) ; Joanne (Soonie - Main) (Kanna - Sub) ; Larimar (Naiah - Main) (Erusha - Sub). If listed Subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: 'Ult: Joanne Ult (2.:42~), Larimar Ult(1:50~), Violette Ult (0:56~), Yuria (0:37~) and then Linzy (Thanatos)',
    Gear: 'Gear: Linzy (Thantos) - CDMG, Rest - Speed'
  },
  {
    Id: 100,
    Pos_1: 'yuria',
    Pos_2: 'wheri',
    Pos_3: 'larimar',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'mephistopheles-dawn',
    Formation: 'Defense',
    Mode: 'World Raid Lighthouse',
    Treasure: '',
    Explanation:
      '{20-Jan-2025} Partners: Yuria = Joanne (Main), Erusha (Sub); Wheri = Naiah (Main), Catherine (Sub); Larimar = Dora (Main), Eve (Sub); Linzy-Thanatos = Adrianne (Main), Garnet (Sub); Mephi (Dawn) = Linzy (Main), Petra (Sub) - If listed subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: 'Ult: Larimar around 2:01; Wheri around 1:29; Yuria around 1:09; Mephi (Dawn) around 0:39; Linzy (Thanatos) at 0:30 just before groggy phase ends',
    Gear: 'Gear: Linzy (Thanatos), Mephi (Dawn) - CDMG, Rest - Speed'
  },
  {
    Id: 101,
    Pos_1: 'wheri',
    Pos_2: 'catherine-radiance',
    Pos_3: 'yuria',
    Pos_4: 'larimar',
    Pos_5: 'linzy-thanatos',
    Formation: 'Defense',
    Mode: 'World Raid Lighthouse',
    Treasure: '',
    Explanation:
      '{07-Dec-2024} Partners: Linzy (Thanatos) = Violette (Main), Petra (Sub); Larimar = Dora (Main), Eve (Sub); Yuria = Linzy (Main), Erusha (Sub); Catherine (Radiance) = Joanne (Main), Vivienne (Sub); Wheri = Naiah (Main), Lute (Sub) - If listed Subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: "Ult: Wheri (1:39); Yuria (1:09), Larimar (0:44). Use Linzy's ult during second groggy phase just before Larimar's sentence is about to expire",
    Gear: 'Gear: Linzy (Thantos) - CDMG or ATK, Rest - Speed'
  },
  {
    Id: 102,
    Pos_1: 'catherine-radiance',
    Pos_2: 'violette',
    Pos_3: 'yuria',
    Pos_4: 'linzy-thanatos',
    Pos_5: 'larimar',
    Formation: 'Defense',
    Mode: 'World Raid Lighthouse',
    Treasure: '',
    Explanation:
      '{Old} Partners: Catherine (Radiance) (Soonie - Main) (Vivienne - Sub) ; Violette (Catherine - Main) (Kanna - Sub) ; Yuria (Linzy - Main) (Lute - Sub) ; Linzy (Thanatos) (Renee - Main) (Petra - Sub) ; Larimar (Velanna - Main) (Eve - Sub). If listed Subs are low ascension, you can replace them with higher ascension souls that provide the same buff',
    Subs: "Ult: Don't use Catherine (Radiance) Ult at all, save Linzy (Thantos) Ult for the end (or as late as possible before dying), save Yuria Ult for survival as necessary.",
    Gear: 'Gear: Linzy (Thantos) - CDMG, Rest - Speed'
  }
];
