import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './teams.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Form, Row } from 'react-bootstrap';
import lodash from 'lodash';
import { ESTeams } from '../../modules/es/ratings/es-teams';
import { EsTeamEntry } from '../../modules/es/common/components/eversoul-team';
import Select from 'react-select';

const ESteamDB: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [currentFormation, setCurrentFormation] = useState('All');
  const [currentMode, setCurrentMode] = useState('All');
  const [visibleTeams, setVisibleTeams] = useState(ESTeams);
  const [textSearch, setTextSearch] = useState('');

  const formationOptions = [
    { value: 'All', label: 'All Formations' },
    { value: 'Assault', label: 'Assault' },
    { value: 'Basic', label: 'Basic' },
    { value: 'Defense', label: 'Defense' },
    { value: 'Ranged', label: 'Ranged' }
  ];

  const sortChangeHandler = (e) => {
    setCurrentFormation(e.value);
  };

  const defaultSortOption = currentFormation
    ? formationOptions.find((opt) => opt.value === currentFormation)
    : null;

  const modeOptions = [
    { value: 'All', label: 'All Modes' },
    { value: 'Battlefront', label: 'Battlefront/Unlimited Depths' },
    { value: 'Boss - Labyrinth', label: 'Boss - Labyrinth' },
    { value: 'Eden Alliance - Gaon', label: 'Eden Alliance - Gaon' },
    { value: 'Eden Alliance - Troyca', label: 'Eden Alliance - Troyca' },
    { value: 'Guild Raid Dark Knight', label: 'Dark Knight (Guild Raid)' },
    { value: 'Guild Raid Gaia', label: 'Gaia (Guild Raid)' },
    { value: 'Guild Raid Pluto', label: 'Pluto (Guild Raid)' },
    { value: 'Guild Raid Rudra', label: 'Rudra (Guild Raid)' },
    {
      value: 'Guild Raid - Chloe (Throwback)',
      label: 'Chloe (Throwback Guild Raid)'
    },
    { value: 'Gate Beast', label: 'Beast Gate/Depths' },
    { value: 'Gate Fairy', label: 'Fairy Gate/Depths' },
    { value: 'Gate Human', label: 'Human Gate/Depths' },
    { value: 'Gate Undead', label: 'Undead Gate/Depths' },
    { value: 'Infinite Raid', label: 'Infinite Raid' },
    { value: 'ESS Adrianne', label: 'Adrianne (Evil Soul)' },
    { value: 'ESS Aira', label: 'Aira (Evil Soul)' },
    { value: 'ESS Aki', label: 'Aki (Evil Soul)' },
    { value: 'ESS Ayame', label: 'Ayame (Evil Soul)' },
    { value: 'ESS Catherine', label: 'Catherine (Evil Soul)' },
    { value: 'ESS Jacqueline', label: 'Jacqueline (Evil Soul)' },
    { value: 'ESS Lizelotte', label: 'Lizelotte (Evil Soul)' },
    { value: 'ESS Naiah', label: 'Naiah (Evil Soul)' },
    { value: 'ESS Vivienne', label: 'Vivienne (Evil Soul)' },
    { value: 'IR Cheese', label: 'Cheese (Unicat Boss)' },
    { value: 'IR Choco', label: 'Choco (Unicat Boss)' },
    { value: 'IR Cookie', label: 'Cookie (Unicat Boss)' },
    { value: 'IR Tabby', label: 'Tabby (Unicat Boss)' },
    { value: 'IR Tofu', label: 'Tofu (Unicat Boss)' },
    { value: 'World Raid Behemoth', label: 'Behemoth (World Raid)' },
    { value: 'World Raid Lighthouse', label: 'Lighthouse (World Raid)' }
  ];

  const modeChangeHandler = (e) => {
    setCurrentMode(e.value);
  };

  const defaultModeOption = currentMode
    ? modeOptions.find((opt) => opt.value === currentMode)
    : null;

  useEffect(() => {
    let filtered = ESTeams;

    filtered = filtered.sort((a, b) => (b.Id < a.Id ? 1 : -1));

    setVisibleTeams(filtered);
    forceUpdate();
  }, []);

  useEffect(() => {
    let filtered = ESTeams;

    if (currentFormation != 'All') {
      filtered = filtered.filter((team) => team.Formation === currentFormation);
    }

    if (currentMode != 'All') {
      filtered = filtered.filter((team) => team.Mode === currentMode);
    }

    if (textSearch != '') {
      filtered = filtered.filter(
        (team) =>
          team.Pos_1.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
          team.Pos_2.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
          team.Pos_3.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
          team.Pos_4.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
          team.Pos_5.toLowerCase().indexOf(textSearch.toLowerCase()) > -1
      );
      filtered = lodash.uniqBy(filtered, 'Id');
    }

    filtered = filtered.sort((a, b) => (b.Id < a.Id ? 1 : -1));

    setVisibleTeams(filtered);
    forceUpdate();
  }, [currentFormation, currentMode, textSearch]);

  const items = visibleTeams.map((weapon, idx) => {
    return (
      <div key={idx}>
        <EsTeamEntry id={weapon.Id} mode="icon" />
      </div>
    );
  });

  return (
    <DashboardLayout className={'generic-page es-teams-page '} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Database</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/eversoul/categories/category_adr.png"
            alt="Eversoul Team Database"
          />
        </div>
        <div className="page-details">
          <h1>Eversoul Team Database</h1>
          <h2>The best teams you can use in Eversoul in various modes.</h2>
          <p>
            Last updated: <strong>24/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Eversoul Teams"
        subtitle={
          <span className="additional-text">
            Showing {visibleTeams.length} team
            {visibleTeams.length !== 1 && 's'}
          </span>
        }
      />
      <Row xs={1} xl={3} xxl={3} className="filter">
        <Col>
          <Form.Control
            size="lg"
            type="text"
            placeholder="Search for characters"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
        </Col>
        <Col>
          <Select
            options={formationOptions}
            className="custom-dropdown order-2 order-md-2"
            isSearchable={false}
            onChange={sortChangeHandler}
            defaultValue={defaultSortOption}
            classNamePrefix="custom-dropdown-inner"
          />
        </Col>
        <Col>
          <Select
            options={modeOptions}
            className="custom-dropdown order-2 order-md-2"
            isSearchable={true}
            onChange={modeChangeHandler}
            defaultValue={defaultModeOption}
            classNamePrefix="custom-dropdown-inner"
          />
        </Col>
      </Row>
      <div className="es-teams-container">{items}</div>
    </DashboardLayout>
  );
};

export default ESteamDB;

export const Head: React.FC = () => (
  <Seo
    title="Teams Database | Eversoul | Prydwen Institute"
    description="The best teams you can use in Eversoul in various modes."
    game="eversoul"
  />
);
